<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            key="stand"
            v-if="
              $route.path.includes('/stands/') ||
                $route.path.includes('/statistics/stand/')
            "
            id="breadcrumbsPageTitleCustom"
            class="content-header-title float-left pr-1 mb-0"
          ></h2>
          <h2
            key="all"
            v-else
            id="breadcrumbsPageTitle"
            class="content-header-title float-left pr-1 mb-0"
          >
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/stands/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="4"
      v-if="$route.path.includes('/statistics')"
      style="display: flex !important;"
    >
      <b-col cols="8">
        <b-form-group>
          <flat-pickr
            :placeholder="$t('stats.date_placeholder')"
            v-model="rangeDate"
            class="form-control"
            :config="{ mode: 'range', dateFormat: 'Y-m-d' }"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-button @click="setDate()" variant="primary">
          {{ $t("stats.select") }}
        </b-button>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    flatPickr,
    BFormGroup,
  },
  data() {
    return {
      rangeDate: null,
    };
  },
  methods: {
    setDate() {
      this.$store.commit("set_date", this.rangeDate);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
