export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    header: "menu_name.virtual_fair",
  },
  {
    title: "menu_name.stand",
    route: "ListStand",
    icon: "GridIcon",
  },
  {
    title: "menu_name.totem",
    route: "totem",
    icon: "ColumnsIcon",
  },
  {
    title: "menu_name.meeting_point",
    route: "ListPoints",
    icon: "RepeatIcon",
  },
  {
    title: "menu_name.conference",
    route: "conference",
    icon: "VideoIcon",
  },
  {
    title: "menu_name.users",
    route: "users",
    icon: "EyeIcon",
  },
  {
    header: "menu_name.definition",
  },
  {
    title: "menu_name.personalization",
    route: "personalizations",
    icon: "ImageIcon",
  },
  {
    title: "menu_name.notication",
    route: "notifications",
    icon: "BellIcon",
  },
];
