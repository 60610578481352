<template>
  <b-modal
    id="modal_change_password"
    centered
    :title="$t('change_password')"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    @cancel="resetModal"
    @ok.prevent="changePassword"
    no-close-on-backdrop
    hideHeaderClose
  >
    <validation-observer ref="changeValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!--  old password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="old-password">{{ $t("old_password") }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Old Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="oldPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="old-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--  password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-password">{{ $t("new_password") }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required|password|confirmed:Confirm_Password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="new-password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- confirm password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-confirm-password">
              {{ $t("Confirm_Password") }}
            </label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Confirm_Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="new-confirm_password"
                v-model="confirmPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-confirm-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCardText,
  BInputGroupAppend,
  BCardTitle,
  BInputGroup,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { changePassword } from "@api/auth";
import { password } from "@/@core/utils/validations/validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BInputGroupAppend,
    BForm,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    changePassword() {
      const $this = this;
      this.$refs.changeValidation.validate().then((success) => {
        if (success) {
          changePassword(
            $this.oldPassword,
            $this.password,
            $this.confirmPassword
          )
            .then(() => {
              $this.$toast({
                component: ToastificationContent,
                props: {
                  title: $this.$t("change_password"),
                  icon: "BellIcon",
                },
              });
              $this.resetModal();
            })
            .catch((error) => {
              $this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: "BellIcon",
                },
              });
            });
        }
      });
    },
    resetModal() {
      const $this = this;
      $this.oldPassword = "";
      $this.password = "";
      $this.confirmPassword = "";
      $this.$bvModal.hide("modal_change_password");
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
