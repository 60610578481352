<template>
  <b-modal
    id="modal_feedback"
    centered
    :title="$t('feedback')"
    :ok-title="$t('Send')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    @cancel="resetModal"
    @ok.prevent="sendFeedback"
  >
    <b-card>
      <b-row class="mb-2">
        <b-col md="12">
          <b-form-select v-model="selected" :options="options" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-textarea rows="5" max-rows="9" v-model="text">
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormTextarea,
  BCard,
  BRow,
  BCol,
  BFormSelect,
} from "bootstrap-vue";
import { sendTicket } from "@api/zendesk";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormSelect,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      selected: "Feedback",
      text: "",
      options: [
        { value: "Feedback", text: "Feedback" },
        { value: "Help", text: "Help" },
      ],
    };
  },
  methods: {
    sendFeedback() {
      var $this = this;
      let formData = new FormData();
      formData.append("subject", `${this.selected} - Fairers`);
      formData.append("comment", this.text);
      if (this.text == "") {
        $this.$toast({
          component: ToastificationContent,
          props: {
            title: $this.$t("no_text"),
            icon: "BellIcon",
          },
        });
      } else {
        sendTicket(formData)
          .then((data) => {
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: $this.$t("success_feedback"),
                icon: "BellIcon",
              },
            });
            $this.resetModal();
          })
          .catch((error) => {
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: error,
                icon: "BellIcon",
              },
            });
          });
      }
    },
    resetModal() {
      const $this = this;
      $this.text = "";
      $this.$bvModal.hide("modal_feedback");
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
