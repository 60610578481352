<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center d-none d-lg-flex"
    >
    <b-navbar-nav class="nav align-items-center ml-auto">
    <b-nav-item-dropdown
        id="dropdown-grouped"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        left
        class="dropdown-icon-wrapper"
      >
        <template #button-content>
          <feather-icon icon="GridIcon" size="18" class="align-middle" />
          <span class="ml-50 text-body">{{ $t("quick_menu") }}</span>
        </template>
        <b-dropdown-group
          id="dropdown-group-1"
          :header="$t('Shortcuts')"
          style="width: 180px"
        >
          <b-dropdown-form>
            <b-form-checkbox
              :checked="publish"
              class="custom-control-primary"
              name="check-button"
              switch
              inline
              @change="ChangePublish"
            />
            {{ publish_text }}
          </b-dropdown-form>
          <b-dropdown-item @click="OpenPreview">
            <feather-icon size="16" icon="FileIcon" class="mr-50" />
            <span class="user-status">{{ $t("preview") }}</span>
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal_embeded>
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span class="user-status">{{ $t("Embeded") }}</span>
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal_feedback>
            <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
            <span class="user-status">
              {{ $t("feedback") }}
            </span>
          </b-dropdown-item>
        </b-dropdown-group>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
      >
        <template #button-content>
          <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          />
        </template>
        <b-dropdown-item
          v-for="localeObj in locales"
          :key="localeObj.locale"
          @click="$i18n.locale = localeObj.locale"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
          <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user user-menu"
      >
        <template #button-content>
          <div v-if="user" class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.email }}</span>
            <span class="user-status">{{ user.role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <!-- <b-dropdown-form>
          <span class="user-status">{{ user.email }}</span>
        </b-dropdown-form> -->
        <b-dropdown-item>
          <feather-icon size="16" icon="KeyIcon" class="mr-50" />
          <span class="user-status" v-b-modal.modal_change_password>
            {{ $t("change_password") }}
          </span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <change-password-modal></change-password-modal>
    <embeded-modal></embeded-modal>
    <feedback-modal></feedback-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownForm,
  BAvatar,
  BDropdownGroup,
  BImg,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
  BCardSubTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import EmbededModal from "../../components/EmbededModal";
import FeedbackModal from "../../components/FeedbackModal";
import { FAIRERS_URL } from "@variables";
import { logout } from "../../libs/api/auth";
import { saveSettings } from "@api/settings";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownForm,
    BAvatar,
    BImg,
    ChangePasswordModal,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BDropdownGroup,
    BFormCheckbox,
    EmbededModal,
    FeedbackModal,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { status: true };
  },
  methods: {
    logOut() {
      const $this = this;
      logout().then(() => {
        $this.$store.commit("unset_user");
        $this.$router.push({ path: "/" });
      });
    },
    OpenPreview() {
      window.open(FAIRERS_URL);
    },
    resetModal() {
      this.$bvModal.hide("modal_change_password");
    },
    ChangePublish() {
      let $this = this;
      let formData = new FormData();
      formData.append("published", $this.publish ? 0 : 1);
      saveSettings(formData)
        .then((data) => {
          $this.$store.commit("set_setting", data);
        })
        .catch(() => {
          $this.$router.push({ path: `/error-404/` });
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
    publish() {
      return this.$store.state.setting.setting
        ? this.$store.state.setting.setting.published == 1
          ? true
          : false
        : false;
    },
    publish_text() {
      return this.$store.state.setting.setting
        ? this.$store.state.setting.setting.published == 1
          ? this.$t("Publish")
          : this.$t("Unpublish")
        : this.$t("Unpublish");
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "it",
        img: require("@/assets/images/flags/it.png"),
        name: "Italiano",
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/uk.png"),
        name: "English",
      },
    ];
    /* eslint-disable global-require */
    return {
      locales,
    };
  },
};
</script>
