import { api } from "./index";

export const sendTicket = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`send_zendesk_ticket`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
