<template>
  <b-modal
    id="modal_embeded"
    centered
    ok-only
    :title="$t('Embeded')"
    :ok-title="$t('close')"
    @shown="showModal"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    size="md"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group class="mb-0 mr-1">
          <label for="textarea-default">Embeded code</label>
          <b-form-textarea
            readonly
            id="textarea-iframe"
            rows="5"
            v-model="iframe_code"
            class="mb-2"
            no-resize
          />
          <b-button
            @click="copyIframeCode"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            {{ $t("copy") }}
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormTextarea,
  BEmbed,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { FAIRERS_URL } from "@variables";

export default {
  components: {
    BEmbed,
    BModal,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    ToastificationContent,
    BFormGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      iframe_code: `<iframe width="1024" height="768" src="${FAIRERS_URL}/" frameBorder="0"></iframe>`,
      url: "",
    };
  },
  methods: {
    showModal() {
      this.url = FAIRERS_URL;
    },
    copyIframeCode() {
      var $this = this;
      navigator.clipboard.writeText(this.iframe_code).then(() => {
        $this.$toast({
          component: ToastificationContent,
          props: {
            title: $this.$t("text_copied"),
            icon: "BellIcon",
          },
        });
      });
    },
  },
};
</script>
